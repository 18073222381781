<template>
  <a-modal
    :title="handle === 'add' ? '新增' : '复制订单'"
    style="top: 8px"
    width="80%"
    v-model="visible"
    :confirmLoading="btnsLoading"
    :maskClosable="false"
    :footer="null"
  >
    <!--    @ok="toSubmit"-->
    <!--    okText="提交"-->

    <a-tabs type="card">
      <a-tab-pane key="1" tab="基本信息">
        <a-form-model
          ref="form"
          :model="rowData"
          :rules="formRule"
          layout="horizontal"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-divider orientation="left">基本信息</a-divider>
          <a-row>
            <a-col :md="12">
              <a-form-model-item label="经销商" prop="dealerName">
                <a-input disabled v-model="rowData.dealerName" placeholder="请输入经销商"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="12">
              <a-form-model-item label="大区事务所" prop="belongFirmName" :autoLink="false" ref="text">
                <a-cascader
                  :options="options"
                  @change="onFirmChange"
                  placeholder="请选择"
                  v-model="RegOff"
                  :field-names="{ label: 'title', value: 'id', children: 'children' }"
                />
              </a-form-model-item>
            </a-col>

            <!--            <a-col :md="12">-->
            <!--              <a-form-model-item label="合同类型" prop="contractType">-->
            <!--                <a-select-->
            <!--                  :disabled="handle === 'edit' ? true : false"-->
            <!--                  placeholder="请选择合同类型"-->
            <!--                  v-model="rowData.contractType"-->
            <!--                >-->
            <!--                  <a-select-option :value="item.id" v-for="(item, index) in contractTypeList" :key="index">-->
            <!--                    {{ item.title }}-->
            <!--                  </a-select-option>-->
            <!--                </a-select>-->
            <!--              </a-form-model-item>-->
            <!--            </a-col>-->
          </a-row>
          <a-row>
            <!--            <a-col :md="12">-->
            <!--              <a-form-model-item label="购销合同类型" prop="purchaseAndSalesContractType">-->
            <!--                <a-radio-group-->
            <!--                  :disabled="handle === 'edit' ? true : false"-->
            <!--                  :options="purchaseAndSalesContractTypeOptions"-->
            <!--                  v-model="rowData.purchaseAndSalesContractType"-->
            <!--                />-->
            <!--              </a-form-model-item>-->
            <!--            </a-col>-->
            <a-col :md="12">
              <a-form-model-item label="购货单位" prop="consigneeType">
                <a-radio-group
                  :disabled="handle === 'edit' || handle === 'add' ? false : true"
                  @change="handleChange"
                  :options="consigneeOptions"
                  v-model="rowData.consigneeType"
                />
              </a-form-model-item>
            </a-col>
            <a-col :md="12">
              <a-form-model-item v-if="checkRadio == 1" label="联系人" prop="companyName">
                <a-input v-model="rowData.companyName" placeholder="请输入联系人"></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-row>
            <!--            <a-col :md="12">-->
            <!--              <a-form-model-item label="发货性质" prop="deliveryProperties">-->
            <!--                <a-radio-group-->
            <!--                  :disabled="handle === 'edit' ? true : false"-->
            <!--                  :options="deliveryPropertiesOptions"-->
            <!--                  v-model="rowData.deliveryProperties"-->
            <!--                />-->
            <!--              </a-form-model-item>-->
            <!--            </a-col>-->
            <!--            <a-col :md="12">-->
            <!--              <a-form-model-item label="是否包邮" prop="flagFreeShipping">-->
            <!--                <a-radio-group-->
            <!--                  :disabled="handle === 'edit' ? true : false"-->
            <!--                  :options="flagFreeShippingOptions"-->
            <!--                  v-model="rowData.flagFreeShipping"-->
            <!--                />-->
            <!--              </a-form-model-item>-->
            <!--            </a-col>-->
          </a-row>

          <a-row>
            <!-- <a-col :md="12">
              <a-form-model-item label="供应商" prop="supplier" :autoLink="false" ref="supplier">
                <a-select
                  :disabled="handle === 'edit' ? true : false"
                  @change="onChange"
                  labelInValue
                  placeholder="请选择供应商"
                  v-model="rowData.supplier"
                >
                  <a-select-option :value="item.id" v-for="(item, index) in supplierList" :key="index">
                    {{ item.title }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col> -->

            <!--            <a-col :md="12">-->
            <!--              <a-form-model-item label="期望到货日期">-->
            <!--                <DatePicker-->
            <!--                  :disabled="handle === 'edit' ? true : false"-->
            <!--                  :startTime.sync="rowData.expectTime"-->
            <!--                ></DatePicker>-->
            <!--              </a-form-model-item>-->
            <!--            </a-col>-->

            <!--            <a-col :md="12">-->
            <!--              <a-form-model-item-->
            <!--                icon="down"-->
            <!--                label="仓库"-->
            <!--                class="required_fields"-->
            <!--                :prop="changeRadio == 1 ? 'warehouseId' : ''"-->
            <!--                v-if="changeRadio == 1"-->
            <!--                :autoLink="false"-->
            <!--                ref="test"-->
            <!--              >-->
            <!--                <a-select-->
            <!--                  :disabled="handle === 'edit' ? true : false"-->
            <!--                  labelInValue-->
            <!--                  placeholder="请选择仓库"-->
            <!--                  @change="handleWarehouseChange"-->
            <!--                  v-model="rowData.warehouse"-->
            <!--                >-->
            <!--                  <a-select-option :value="item.id" v-for="(item, index) in warehouseList" :key="index">{{-->
            <!--                    item.title-->
            <!--                  }}</a-select-option>-->
            <!--                </a-select>-->
            <!--              </a-form-model-item>-->
            <!--            </a-col>-->
          </a-row>

          <!--                  <a-row>-->
          <!--                    <a-col :md="12">-->
          <!--                        <a-form-model-item label="收货人" prop="customerName">-->
          <!--                            <a-input :disabled="handle === 'edit' ? true : false" v-model="rowData.customerName" placeholder="客户名"></a-input>-->
          <!--                        </a-form-model-item>-->
          <!--                    </a-col>-->
          <!--                    <a-col :md="12">-->
          <!--                        <a-form-model-item label="联系方式" prop="customerContact">-->
          <!--                            <a-input :disabled="handle === 'edit' ? true : false" v-model="rowData.customerContact" placeholder="客户联系方式"></a-input>-->
          <!--                        </a-form-model-item>-->
          <!--                    </a-col>-->
          <!--                  </a-row>-->
          <!--                  <a-row>-->
          <!--                    <a-col :md="12">-->
          <!--                      <a-form-model-item label="收货地址" prop="provinceId">-->
          <!--                        <PCD-->
          <!--                          ref="pcd"-->
          <!--                          placeholder="请选择区域"-->
          <!--                          :province.sync="rowData.provinceName"-->
          <!--                          :city.sync="rowData.cityName"-->
          <!--                          :district.sync="rowData.areaName"-->
          <!--                          :provinceId.sync="rowData.provinceId"-->
          <!--                          :cityId.sync="rowData.cityId"-->
          <!--                          :districtId.sync="rowData.areaId"-->
          <!--                          :disabled="handle === 'edit' ? true : false">-->
          <!--                        </PCD>-->
          <!--                      </a-form-model-item>-->
          <!--                    </a-col>-->
          <!--                    <a-col :md="12">-->
          <!--                        <a-form-model-item label="详细收货地址" prop="address">-->
          <!--                            <a-input :disabled="handle === 'edit' ? true : false" v-model="rowData.address" placeholder="详细收货地址"></a-input>-->
          <!--                        </a-form-model-item>-->
          <!--                    </a-col>-->
          <!--                  </a-row>-->

          <a-row v-if="rowData.dealerName != null">
            <a-col :md="12">
              <!--<a-icon type='environment' />-->
              <a-form-model-item label="寄送至：">
                <a-row>
                  <a-col :md="18">
                    {{ rowData.customerName }} {{ rowData.customerContact }}
                    <a v-if="rowData.customerName == null" @click="onCheckAddress">新建收货地址</a>
                  </a-col>
                  <a-col :md="6">
                    <a @click="onCheckAddress" v-if="rowData.customerName != null">重新选择地址</a>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :md="24">
                    {{ rowData.provinceName }}{{ rowData.cityName }}{{ rowData.areaName }}{{ rowData.address }}
                  </a-col>
                </a-row>
              </a-form-model-item>
            </a-col>
            <a-col :md="12"> </a-col>
          </a-row>
          <a-row>
            <!-- <a-col :md="12">
                      <a-form-model-item label="订单类型" prop="orderType">
                        <a-select :disabled="handle === 'edit' ? true : false" placeholder="请选择订单类型" v-model="rowData.orderType">
                          <a-select-option :value="item.id" v-for="(item, index) in orderTypeList" :key="index">{{item.title}}</a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col> -->
          </a-row>

          <a-row>
            <a-col :md="24">
              <a-form-model-item label="订单备注" prop="" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
                <a-textarea
                  :disabled="handle === 'edit' ? true : false"
                  v-model="rowData.orderRemark"
                  placeholder=""
                ></a-textarea>
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-divider orientation="left">订单附件</a-divider>
          <a-row>
            <a-col :md="12">
              <a-form-model-item label="上传附件">
                <QiniuUpload
                  :disabled="handle === 'edit' || handle === 'add' ? false : true"
                  type="file"
                  :value.sync="imageUrl"
                  :size="5"
                ></QiniuUpload>
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-divider orientation="left">商品信息</a-divider>
          <a-row>
            <a-col :md="24">
              <a-form-model-item label="添加商品" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
                <a-button type="primary" @click="onOpenProModal()">添加商品</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
          <div v-if="productList.length > 0">
            <a-table :scroll="{ x: 1970 ,y:400}" :data-source="productList" bordered :rowKey="(record) => record.id" :pagination="false">
              <a-table-column title="商品图片" data-index="imgUrl" :width="100" :ellipsis="true" align="center" fixed="left">
                <template slot-scope="text, row">
                  <ZoomMedia style="height: 50px" :value="row.imgUrl"></ZoomMedia>
                </template>
              </a-table-column>
              <a-table-column
                title="商品名称"
                data-index="name"
                :width="200"
                align="left"
                fixed="left"
              ></a-table-column>

              <a-table-column
                title="品牌"
                data-index="brandName"
                :width="120"
                :ellipsis="true"
                align="center"
              ></a-table-column>
              <a-table-column
                title="商品分类"
                data-index="categoryName"
                :width="120"
                :ellipsis="true"
                align="center"
              ></a-table-column>
              <a-table-column title="规格" data-index="specStr" :width="120" align="center"></a-table-column>
              <a-table-column title="型号" data-index="barCode" :width="120" align="center"></a-table-column>
               <a-table-column title="单位" data-index="proUnitVo.name" :width="150" :ellipsis="true" align="center">
                <template slot-scope="text, row">
                  <span v-if="row.proUnitVo.point != 0"
                    >{{ row.proUnitVo.name }} <span>(保留{{ row.proUnitVo.point }}小数)</span></span
                  >
                  <span v-if="row.proUnitVo.point == 0">{{ row.proUnitVo.name }} <span>(保留整数)</span></span>
                </template>
              </a-table-column>
              <!--              <a-table-column-->
              <!--                title="库存"-->
              <!--                data-index="saleQuantity"-->
              <!--                :width="100"-->
              <!--                align="center"-->
              <!--              >-->
              <!--                <template slot-scope="text,row">{{ row.saleQuantity - row.orderOccupy }}</template>-->
              <!--              </a-table-column>-->
              <a-table-column title="数量" data-index="purchaseNum" :width="140" :ellipsis="true" align="center">
                <template slot-scope="text, row,index">
                  <a-input-number @change="getRabateRow(row.purchaseNum)" :min="1" v-model="row.purchaseNum" @blur="blurPurchaseNum(index)"></a-input-number>
                </template>
              </a-table-column>
              <!--              <a-table-column title="建议零售价" data-index="price" :width="120" :ellipsis="true" align="right">-->
              <!--                <template slot-scope="text">{{ text.toFixed(2) }}</template>-->
              <!--              </a-table-column>-->
              <a-table-column
                title="标准开票价（D价）"
                data-index="sellPrice"
                :width="150"
                :ellipsis="true"
                align="right"
              >
                <template slot-scope="text">{{ text.toFixed(2) }}</template>
              </a-table-column>
              <a-table-column title="申请单价" data-index="proMatchPrice" :width="120" :ellipsis="true" align="right">
                <template slot-scope="text, row">{{ row.proMatchPrice.toFixed(2) }}</template>
              </a-table-column>
              <!--              <a-table-column-->
              <!--                title="可使用折扣金额"-->
              <!--                data-index="canUsedRebatePrice"-->
              <!--                :width="120"-->
              <!--                :ellipsis="true"-->
              <!--                align="right"-->
              <!--              >-->
              <!--                <template slot-scope="text">{{ text.toFixed(2) }}</template>-->
              <!--              </a-table-column>-->
              <a-table-column
                title="已使用折扣金额"
                data-index="usedRebateTotal"
                :width="120"
                :ellipsis="true"
                align="right"
              >
                <template slot-scope="text">{{ text.toFixed(2) }}</template>
              </a-table-column>
              <a-table-column title="D价合计" data-index="" :width="120" :ellipsis="true" align="center">
                <template slot-scope="text, row">
                  {{ (row.sellPrice * row.purchaseNum).toFixed(2) }}
                </template>
              </a-table-column>
              <a-table-column title="实付合计" data-index="" :width="120" :ellipsis="true" align="center">
                <template slot-scope="text, row">
                  {{ (row.proMatchPrice * row.purchaseNum - row.usedRebateTotal).toFixed(2) }}
                </template>
              </a-table-column>
              <a-table-column title="实际折扣率" data-index="" :width="120" :ellipsis="true" align="center">
                <template slot-scope="text, row">
                  {{
                    ((
                      (row.proMatchPrice * row.purchaseNum - row.usedRebateTotal) /
                      (row.sellPrice * row.purchaseNum)
                    )*100).toFixed(2)
                  }}%
                </template>
              </a-table-column>
              <a-table-column title="机型二" data-index="" :width="150" :ellipsis="true" align="center">
                <template slot-scope="text, row">
                  <a-select
                    placeholder="请选择"
                    style="width: 100%"
                    v-model="row.modelTwo"
                  >
                    <a-select-option :value="item.id" v-for="(item, index) in modelTowList" :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </template>
              </a-table-column>
              <a-table-column title="备注" data-index="remark" :width="120" :ellipsis="true" align="center">
                <template slot-scope="text, row">
                  <a-input v-model="row.remark" placeholder="请输入"></a-input>
                </template>
              </a-table-column>
              <a-table-column title="操作" data-index="action" :width="120" :ellipsis="true" align="center">
                <template slot-scope="text, row">
                  <a @click="onDelete(row.goodsId)">删除</a>
                </template>
              </a-table-column>
            </a-table>
          </div>
          <div class="table_outer" v-if="productList.length > 0 && canUsedRebateTotal > 0">
            <div class="rebate">
              <div class="rebate-title">选择折扣：</div>
              <div style="display: flex; margin-top: 10px; align-items: flex-end">
                <div class="money-outer">
                  <div class="rebate-total-money">可用折扣金额：{{ canUsedRebateTotal }}</div>
                  <!-- <div class="rebate-order-money">
                    当前订单最高可用折扣金额： {{ orderUsableRebateTotal.toFixed(2) }}
                  </div> -->
                </div>
                <!-- <div class="btns" @click="onChoose()"><u>选择其他折扣</u></div> -->
              </div>
              <a-collapse default-active-key="1" :bordered="false" style="margin-top: 10px">
                  <template #expandIcon="props">
                    <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                  </template>
                  <a-collapse-panel key="1" header="可用折扣" class="coll-pan" >
                    <a-checkbox-group
                      style="width: 100%;"
                      v-if="rebateRowData.canUsedList != null && rebateRowData.canUsedList.length > 0"
                      v-model="checkedRebateList"
                      @change="onChangeRebate"
                    >
                      <div class="rebate-check-Price">
                        <div>
                          <a-checkbox
                            class="rebate-check"
                            v-for="(item, index) in rebateRowData.canUsedList"
                            :key="index"
                            :value="item.id"
                          >
                            <div  class="checked_inline rebate-discount" style="width: 100%;">
                              <span class="checkbox_text span">
                                <label class="click_label" @click.stop="onCheckRebate(item,$event)" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap; width: 205px;"> {{ item.rebateName }} </label>
                              </span>
                              <span class="span">剩余：{{ item.remainingAmount  | formatMoney  }}</span>
                              <span class="span">有效期：{{ item.startTime }} - {{ item.endTime }}</span>
                              <span v-if="item.pr != null || 0" class="span">本次使用：<span style="color:red;">{{ item.pr | formatMoney }}</span></span>
                            </div>
                          </a-checkbox>
                        </div>
                      </div>
                    </a-checkbox-group>
                    <div v-else>暂无可使用折扣</div>
                  </a-collapse-panel>
                  <a-collapse-panel key="2" header="不可用折扣" class="coll-pan" >
                    <a-checkbox-group v-model="checkedRebateList"  style="width: 100%;">
                      <a-checkbox
                        style="margin-bottom: 10px"
                        class="rebate-check"
                        disabled
                        v-for="(item, index) in rebateRowData.canNotUsedList"
                        :key="index"
                        :value="item.id"
                      >
                        <div class="checked_inline rebate-discount"  style="width: 100%;">
                          <span class="checkbox_text span">{{ item.rebateName }} </span>
                          <span class="span">剩余：{{ item.remainingAmount  | formatMoney  }}</span>
                          <span class="span">有效期：{{ item.startTime }} - {{ item.endTime }}</span>
                        </div>

                      </a-checkbox>
                    </a-checkbox-group>
                  </a-collapse-panel>
              </a-collapse>
            </div>
          </div>
          <div class="product_Amount_info" v-if="productList.length > 0">
            <div class="content">
              <div class="text"></div>
              <div class="text">
                <span>商品总数：</span><span>{{ totalQuantity }}</span
                ><span style="margin-left: 20px"> D价总金额：</span><span>{{ totalMoney.toFixed(2) }}</span>
              </div>
              <div class="text">
                <span>折扣：</span><span class="text-color">- {{ totalRebatePrice.toFixed(2) }}</span>
              </div>
              <div class="text">
                <span>政策：</span><span class="text-color">- {{ policyTotalPrice.toFixed(2) }}</span>
              </div>
              <div class="text">
                <span>实付金额：￥</span
                ><span class="text-color real-total-price">{{ realTotalPrice.toFixed(2) }}</span>
              </div>
            </div>
          </div>
        </a-form-model>
      </a-tab-pane>
<!--      <a-tab-pane key="2" tab="操作日志" v-if="handle != 'add'">-->
<!--        <log-page :orderId="orderId"></log-page>-->
<!--      </a-tab-pane>-->
    </a-tabs>
    <ProductModal ref="ProductModal" @get-product-list="getProductList"></ProductModal>
    <ChooseRebate ref="ChooseRebate" @checkout="getRebateIds"></ChooseRebate>
    <CheckAddressModal ref="CheckAddressModal" @refresh="getEcho"></CheckAddressModal>
    <DealerRebateCheckModal ref="DealerRebateCheckModal"></DealerRebateCheckModal>

    <div class="footer-bts" v-if="handle != 'check'">
      <a-button key="back" @click="handleCancel">取消</a-button>
      <a-button key="save" :loading="loading" @click="toSubmit(9)">保存草稿</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="toSubmit(0)">提交审核</a-button>
    </div>
  </a-modal>
</template>

<script>
import { editOrderInfo, selectByIdOrderInfo, addOrderInfo } from '../api/OrderInfoApi'
import ProductModal from './ProductModal.vue'
import ChooseRebate from './ChooseRebate.vue'
import LogPage from './LogPage.vue'
import { formatMoneyOfRound } from '../../../utils/util.js'
// import SelectDealerModal from '../../common/SelectDealerModal.vue'
import DealerRebateCheckModal from '@/views/dealer_rebate/components/DealerRebateCheckModal.vue'
import CheckAddressModal from './CheckAddressModal.vue'
import '../css/style.css'

export default {
  components: {
    ProductModal,
    ChooseRebate,
    LogPage,
    DealerRebateCheckModal,
    // SelectDealerModal,
    CheckAddressModal,
  },
  data() {
    return {
      visible: false,
      loading: false,
      handle: 'add',
      rowData: {},
      // 表单验证
      formRule: {
        dealerName: [{ required: true, message: '请选择经销商', trigger: 'change' }],
        belongFirmName: [{ required: true, message: '请选择事务所', trigger: 'change' }],
        firmName: [{ required: true, message: '请选择事务所', trigger: 'change' }],
        companyName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        consigneeType: [{ required: true, message: '请选择抬头类型', trigger: 'change' }],
        payType: [{ required: true, message: '请支付选择方式', trigger: 'change' }],
        contractType: [{ required: true, message: '请选择合同类型', trigger: 'change' }],
        orderType: [{ required: true, message: '请选择订单类型', trigger: 'change' }],
        supplier: [{ required: true, message: '请选择供应商', trigger: 'change' }],
        taxType: [{ required: true, message: '请选择发票类型', trigger: 'change' }],
        taxTitle: [{ required: true, message: '请输入发票抬头', trigger: 'blur' }],
        taxCompany: [{ required: true, message: '请输入单位名称', trigger: 'blur' }],
        taxNum: [{ required: true, message: '请输入纳税人识别号', trigger: 'blur' }],
      },
      regionList: [],
      payTypeList: [],
      contractTypeList: [],
      orderTypeList: [],
      goodsChannelList: [],
      consigneeOptions: [
        {
          label: '公司',
          value: 2,
        },
        {
          label: '个人',
          value: 1,
        },
      ],
      deliveryPropertiesOptions: [
        {
          label: '正常发货',
          value: 1,
        },
        {
          label: '赠送',
          value: 2,
        },
        {
          label: '售后',
          value: 3,
        },
      ],
      flagFreeShippingOptions: [
        {
          label: '是',
          value: true,
        },
        {
          label: '否',
          value: false,
        },
      ],
      purchaseAndSalesContractTypeOptions: [
        {
          label: '个人',
          value: 1,
        },
        {
          label: '公司',
          value: 2,
        },
      ],
      productList: [], // 选中商品
      dealerList: [],
      dealerId: [],
      checkedList: [],
      totalQuantity: 0, // 总数量
      totalMoney: 0, // 总金额
      realTotalPrice: 0, // 实付金额
      totalRebatePrice: 0, // 折扣总金额
      policyTotalPrice: 0, // 政策总金额,
      orderUsableRebateTotal: 0, // 当前订单可使用折扣总额
      btnsLoading: false,
      checkedRebateList: [],
      checkRadio: 0,
      supplierList: [],
      warehouseList: [],
      changeRadio: 0,
      canUsedRebateTotal: 0,
      orderId: '',
      imageUrl: '',
      modelTowList: [
        {
          id: 1,
          name: 1,
        },
        {
          id: 2,
          name: 2,
        },
        {
          id: 3,
          name: 3,
        },
        {
          id: 4,
          name: 4,
        },
        {
          id: 5,
          name: 5,
        },
        {
          id: 6,
          name: 6,
        },
      ],
      rebateRowData:[],
      discountPrice: [],
      options: [],
      RegOff: [],
    }
  },
  computed: {},
  methods: {
    getPrice(row){
      this.supplierDiscount = row.supplierDiscount
      let list = []
      list = this.productList.map(item=>{
        item.proMatchPrice = item.sellPrice *(this.supplierDiscount/100)
        return item
      })
      this.productList = list
      this.getRabateRow(1)
    },
    // 获取行数据
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true
      this.productList = []
      this.dealerId = []
      this.rowData = {}
      this.imageUrl = ''
      this.orderId = row.id
      this.rowData.dealerName = this.$store.getters.userInfo.dealerName
      this.rowData.dealerId = this.$store.getters.userInfo.dealerId

      // 获取大区和事务所数据
      this.axios.post('/api/firm/mhiac/mhiacFirm/getTreeByDealerId/' + this.rowData.dealerId).then((res) => {
        this.options = res.body
      })

      //this.getEnumerationList()
      if (handle === 'add') {
        //this.$set(this.rowData, 'consigneeType', 1)
        //this.$set(this.rowData, 'taxType', 1)
        //this.$set(this.rowData, 'flagFreeShipping', true)
        this.$set(this.rowData, 'deliveryProperties', 1)
        //this.$set(this.rowData, 'purchaseAndSalesContractType', 1)
        this.checkRadio = 0
        this.axios.get('/api/dealer/dealer/dealerInfo/dealer/selectDealerOtherByOrder/0').then((res) => {
          // 获取默认地址
          const da = res.body.defaultAddress
          this.canUsedRebateTotal = res.body.canUsedRebateTotal
          this.$set(this.rowData, 'customerName', da.contacts)
          this.$set(this.rowData, 'customerContact', da.phone)
          this.$set(this.rowData, 'provinceName', da.province)
          this.$set(this.rowData, 'provinceId', da.provinceId)
          this.$set(this.rowData, 'cityName', da.city)
          this.$set(this.rowData, 'cityId', da.cityId)
          this.$set(this.rowData, 'areaName', da.area)
          this.$set(this.rowData, 'areaId', da.areaId)
          this.$set(this.rowData, 'address', da.address)
          this.$set(this.rowData, '', da.name)
        })
      } else if (handle === 'edit') {
        selectByIdOrderInfo(row.id).then((res) => {
          this.dealerId = [res.body.bigAreaId, res.body.firmId, res.body.dealerId]
          this.rowData = res.body
          this.rowData.belongAreaId = res.body.bigAreaId
          this.rowData.belongFirmId = res.body.firmId
          this.rowData.belongAreaName = res.body.bigAreaName
          this.rowData.belongFirmName = res.body.firmName
          this.RegOff = [res.body.bigAreaId, res.body.firmId]
          //this.rowData.contractType = Number(res.body.contractType)
          //this.rowData.orderType = Number(res.body.orderType)
          this.rowData.consigneeType = Number(res.body.consigneeType)
          this.rowData.companyName = res.body.companyName
          this.checkRadio = Number(res.body.consigneeType)
          //this.rowData.deliveryProperties = Number(res.body.deliveryProperties)
          //this.rowData.purchaseAndSalesContractType = Number(res.body.purchaseAndSalesContractType)
          this.imageUrl = res.body.filesVOS
            ? res.body.filesVOS
              .map((e) => {
                return e.fileUrl
              })
              .toString()
            : ''
          console.log(this.rowData.orderInfoDetailVOList);

          const goodIds = this.rowData.orderInfoDetailVOList.map(item=>item.goodsId);
          //构建商品ID ，商品数量对象
          const goodsList = this.rowData.orderInfoDetailVOList.map(item=> {
            return{
              goodsId:item.goodsId,
              purchaseNum : item.purchaseNum,
              proMatchPrice:item.proMatchPrice
            }
          });

          if(goodIds){
            this.initProductList(this.rowData.dealerId,goodIds,goodsList,this.rowData.orderCode);
          }


          this.axios.get(`/api/dealer/dealer/dealerInfo/dealer/selectDealerOtherByOrder/0`).then((res) => {
            this.canUsedRebateTotal = res.body.canUsedRebateTotal
            //this.getEcho(res.body.defaultAddress)
          })

          // this.$nextTick(() => {
          //   this.$refs.pcd.setPCD()
          // })
        })
      }
    },

    // 获取页面枚举数据
    getEnumerationList() {
      // this.axios.get('/api/firm/mhiac/mhiacArea/listAll').then((res) => {
      //   // 获取大区数据
      //   this.regionList = res.body
      // })
      // this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
      //   // 获取大区事务所经销商数据
      //   this.dealerList = res.body
      // })
      // this.axios.get('/api/base/system/dictionary/selectByCode/' + '合同类型').then((res) => {
      //   // 获取字典合同类型
      //   this.contractTypeList = res.body
      // })
      // this.axios.get('/api/base/system/dictionary/selectByCode/' + '订单类型').then((res) => {
      //   // 获取字典订单类型
      //   this.orderTypeList = res.body
      // })
      // this.axios.get('/api/base/system/dictionary/selectByCode/' + '供应商').then((res) => {
      //   // 获取字典供应商
      //   this.supplierList = res.body
      // })
      // this.axios.get('/api/base/system/dictionary/selectByCode/' + '仓库').then((res) => {
      //   // 获取字典仓库
      //   this.warehouseList = res.body
      // })
    },

     blurPurchaseNum(index) {

      let itemArr = this.productList.map((item) => {
        if (item.proUnitVo.point > 0) {
          var s = '^\\d+(\\.\\d{1,' + item.proUnitVo.point + '})?$'
        } else if (item.proUnitVo.point === 0) {
          var s = /^\d+$/
        }
        var re = new RegExp(s)

        return re.test(item.purchaseNum)
      })
      if (itemArr[index] === false) {
         this.productList.forEach((sitem, sindex) => {
          if (index === sindex) {
            if (sitem.proUnitVo.point === 0) {
              sitem.purchaseNum = parseInt(sitem.purchaseNum)
            } else {
              sitem.purchaseNum = sitem.purchaseNum.toFixed(sitem.proUnitVo.point)
            }
          }
        })
        return this.$message.warning('请输入正确的单位小数')
      }

      let itemNum = this.productList.every((item) => {
        return item.purchaseNum != 0
      })
      if (itemNum === false) {
        return this.$message.warning('选择商品的数量不能为0')
      }
    },
    // 打开选择地址弹框
    onCheckAddress() {
      if (!this.rowData.dealerId) {
        return this.$message.warning('请选择经销商')
      }
      this.$refs.CheckAddressModal.show(this.rowData.dealerId, this.rowData.addressId)
    },

    // 收获类型为单位时打开弹框
    handleChange(e) {
      this.checkRadio = e.target.value
      if (this.checkRadio == 2) {
        this.$set(this.rowData, 'companyName', this.$store.getters.userInfo.dealerName)
      } else {
        this.$set(this.rowData, 'companyName', '')
      }
    },

    // onChange(val) {
    //   this.$refs.supplier.onFieldChange()
    //   if (val.key == 11) {
    //     this.changeRadio = 1
    //   } else {
    //     this.changeRadio = 2
    //   }
    //   this.rowData.supplierName = val.label
    //   this.rowData.supplierId = val.key
    // },

    // onDealerChange(val, data) {
    //   this.$refs.test.onFieldChange()
    //   this.rowData.bigAreaId = val[0]
    //   this.rowData.bigAreaName = data[0].title
    //   this.rowData.firmId = val[1]
    //   this.rowData.firmName = data[1].title
    //   // this.rowData.dealerId = val[2]
    //   // this.rowData.dealerName = data[2].title
    // },
    //
    // handleWarehouseChange(val) {
    //   this.$refs.test.onFieldChange()
    //   this.rowData.warehouseId = val.key
    //   this.rowData.warehouseName = val.label
    // },
    //
    // handleWarehouseChange(val) {
    //   this.$refs.test.onFieldChange()
    //   this.rowData.warehouseId = val.key
    //   this.rowData.warehouseName = val.label
    // },

    // toSelectConsumer(row) {
    //   this.$set(this.rowData, 'dealerId', row.id)
    //   this.$set(this.rowData, 'dealerName', row.name)
    //   this.$set(this.rowData, 'companyName', row.name)
    //   this.axios.get(`/api/dealer/dealer/dealerInfo/manager/selectDealerOtherByOrder/${row.id}`).then((res) => {
    //     this.canUsedRebateTotal = res.body.canUsedRebateTotal
    //     this.getEcho(res.body.defaultAddress)
    //   })
    //   if (this.productList.length > 0) {
    //     let ids = this.productList.map((e) => e.id)
    //     let data = {
    //       dealerId: this.rowData.dealerId,
    //       goodsIds: ids,
    //     }
    //     this.axios
    //       .post(`/api/order/order/orderInfo/queryProListAndPolicy`, data)
    //       .then((res) => {
    //         if (res.code === 200) {
    //           let tmpArr = res.body.orderInfoDetailGoodsVOS
    //           tmpArr.forEach((e) => {
    //             let isExist = false
    //             let policyInfoVOS = []
    //             this.productList.forEach((i) => {
    //               if (e.id === i.id) {
    //                 isExist = true
    //                 policyInfoVOS = e.policyInfoVOS
    //               }
    //               if (isExist) {
    //                 i.policyInfoVOS = policyInfoVOS
    //               }
    //             })
    //           })
    //           this.productList = tmpArr.map((e) => {
    //             return {
    //               ...e,
    //               goodsId: e.id,
    //               optKey: e.policyInfoVOS != null ? e.policyInfoVOS[0].optKey : 0,
    //               purchaseNum: 1,
    //               proMatchPrice: e.sellPrice,
    //               remark: '',
    //             }
    //           })
    //         }
    //       })
    //       .catch((err) => {})
    //   }
    // },

    // 把获取到的地址回显到页面
    getEcho(row) {
      if (row != null) {
        this.$set(this.rowData, 'customerName', row.contacts)
        this.$set(this.rowData, 'customerContact', row.phone)
        this.$set(this.rowData, 'provinceName', row.province)
        this.$set(this.rowData, 'provinceId', row.provinceId)
        this.$set(this.rowData, 'cityName', row.city)
        this.$set(this.rowData, 'cityId', row.cityId)
        this.$set(this.rowData, 'areaName', row.area)
        this.$set(this.rowData, 'areaId', row.areaId)
        this.$set(this.rowData, 'address', row.address)
        this.$set(this.rowData, 'addressId', row.id)
      } else if (row == null || !row) {
        this.$set(this.rowData, 'customerName', null)
        this.$set(this.rowData, 'customerContact', null)
        this.$set(this.rowData, 'provinceName', null)
        this.$set(this.rowData, 'provinceId', null)
        this.$set(this.rowData, 'cityName', null)
        this.$set(this.rowData, 'cityId', null)
        this.$set(this.rowData, 'areaName', null)
        this.$set(this.rowData, 'areaId', null)
        this.$set(this.rowData, 'address', null)
        this.$set(this.rowData, 'addressId', null)
      }

      // this.$nextTick(() => {
      //   this.$refs.pcd.setPCD()
      // })
    },

    //编辑订单初始化产品
    initProductList(dealerId,goodsIds,goodsList,orderCode){
      const tempData={dealerId:dealerId,goodsIds:goodsIds,goodsList:goodsList,orderCode:orderCode};
      this.axios
        .post(`/api/order/order/orderInfo/newQueryProListAndPolicy`, tempData)
        .then((res) => {
          if (res.code === 200) {
            this.dataList = res.body
            this.getProductListForEdit(this.dataList,goodsList);
          }
        })
        .catch((err) => {
        })
    },

    /**
     * 2021-08-15  编辑商品携带数量
     */
    getProductListForEdit(data,goodsList) {
      const arr = new Map()
      let vo = data.orderInfoDetailGoodsVOS.map((e) => {
        return {
          ...e,
          goodsId: e.id,
          optKey: e.policyInfoVOS != null ? e.policyInfoVOS[0].optKey : 0,
          purchaseNum: 1,
          proMatchPrice: e.sellPrice,
          remark: '',
        }
      })

      goodsList.forEach(function(item, index) {
        vo.forEach(function(item2, index2){
          if(item.goodsId == item2.goodsId){
            item2.purchaseNum = item.purchaseNum
            item2.proMatchPrice=item.proMatchPrice
          }
        })
      })

      const list = this.productList.concat(vo)
      // 最大折扣组合ID
      this.checkedRebateList = data.usedRebateIdList
      this.productList = list.filter((e) => !arr.has(e.goodsId) && arr.set(e.goodsId, 1))

      this.getTotalGoodsInfo()
      // 计算当前商品可用总折扣金额
      this.orderUsableRebateTotal = this.productList
        .map((e) => e.canUsedRebatePrice)
        .reduce((prev, next) => formatMoneyOfRound(prev) + formatMoneyOfRound(next))
        this.isShow();
        this.getRabateRow(1)
    },


    // 监听当前选中数据
    onPolicySelectChange(index) {
      let tmp = this.productList[index]
      let tmpArr = tmp.policyInfoVOS.filter((x) => x.optKey == tmp.optKey)
      let discountNum = tmpArr.length > 0 ? tmpArr[0].discountNum : ''
      tmp.proMatchPrice = tmp.sellPrice * discountNum
      this.$set(this.productList, index, tmp)
      this.getRabateList()
    },
    //  数量，单价改变
     getRabateRow(row) {
      let reqData = {
        dealerId: this.rowData.dealerId,
        orderInfoDetailDTOList: this.productList,
      }
      if(row && row != 0){
          this.axios.post('/api/order/order/orderInfo/getNewRebatePriceByChangPro', reqData).then((res) => {
            this.checkedRebateList = res.body.usedRebateIdList
            this.discountPrice = res.body.rebateTotalListVOList
            this.getProductData(res.body.orderInfoDetailGoodsVOS)
            this.mergeArray()
            this.isShow()
      })

      this.getTotalGoodsInfo()
      }else{
         return this.$message.error('申请单价和数量不能为空，且不能为0')
      }

    },
    // 根据商品数量、政策的不同查询当前商品可用折扣和已使用折扣
    getRabateList() {
      let reqData = {
        dealerId: this.rowData.dealerId,
        orderInfoDetailDTOList: this.productList,
      }

      this.axios.post('/api/order/order/orderInfo/getNewRebatePriceByChangPro', reqData).then((res) => {
        this.checkedRebateList = res.body.usedRebateIdList
        this.discountPrice = res.body.rebateTotalListVOList
        this.getProductData(res.body.orderInfoDetailGoodsVOS)
        this.mergeArray()
        this.isShow()
      })

      this.getTotalGoodsInfo()
    },

    // 根据商品数量、政策的不同查询当前商品可用折扣和已使用折扣
    getProductData(resList) {
      this.productList.forEach((e) => {
        let isExist = false
        let canUsedRebatePrice = 0
        let usedRebateTotal = 0
        if (resList.length > 0) {
          resList.forEach((i) => {
            if (e.id === i.id) {
              isExist = true
              canUsedRebatePrice = i.canUsedRebatePrice < 0 ? 0 : i.canUsedRebatePrice
              usedRebateTotal = i.usedRebateTotal < 0 ? 0 : i.usedRebateTotal
            }
            if (isExist) {
              e.canUsedRebatePrice = canUsedRebatePrice
              e.usedRebateTotal = usedRebateTotal
            } else {
              e.canUsedRebatePrice = 0
              e.usedRebateTotal = 0
            }
          })
        } else {
          this.productList = this.productList.map((e) => {
            return {
              ...e,
              canUsedRebatePrice: 0,
              usedRebateTotal: 0,
            }
          })
        }
      })
      this.getTotalGoodsInfo()
    },


    onFirmChange(val, item) {
      console.log(val[0]+"-"+item[0].title+"-"+val[1]+"-"+item[1].title)
      this.$set(this.rowData, 'belongAreaName', item[0].title)
      this.$set(this.rowData, 'belongFirmName', item[1].title)
      this.$set(this.rowData, 'belongAreaId', val[0])
      this.$set(this.rowData, 'belongFirmId', val[1])
      this.RegOff = [val[0], val[1]]
      this.$refs.text.onFieldChange()
      // this.rowData.areaId = val[1]
      // this.rowData.firmId = val[2]
    },

    // 获取选中折扣
    getRebateIds(item, data) {
      this.checkedRebateList = item
      this.getProductData(data)
    },

    // 打开选择折扣弹框
    onChoose() {
      this.$refs.ChooseRebate.isShow(this.rowData.dealerId,null, this.checkedRebateList, this.productList)
    },

    // 打开商品弹框
    onOpenProModal() {
      if (!this.rowData.dealerId) {
        return this.$notification.warning({ message: '请选择经销商' })
      }

      this.$refs.ProductModal.isShow(this.rowData.dealerId, this.productList)
    },

    getProductList(data) {
      const arr = new Map()
      let vo = data.orderInfoDetailGoodsVOS.map((e) => {
        return {
          ...e,
          goodsId: e.id,
          optKey: e.policyInfoVOS != null ? e.policyInfoVOS[0].optKey : 0,
          purchaseNum: 1,
          proMatchPrice: e.sellPrice,
          remark: '',
        }
      })
      const list = this.productList.concat(vo)
      // 最大折扣组合ID
      this.checkedRebateList = data.usedRebateIdList
      this.productList = list.filter((e) => !arr.has(e.goodsId) && arr.set(e.goodsId, 1))

      this.getTotalGoodsInfo()
      this.getRabateList()
      // 计算当前商品可用总折扣金额
      this.orderUsableRebateTotal = this.productList
        .map((e) => e.canUsedRebatePrice)
        .reduce((prev, next) => formatMoneyOfRound(prev) + formatMoneyOfRound(next));
        this.getRabateRow(1)
        this.mergeArray()
        this.isShow();
    },

    // 获取商品价格汇总
    getTotalGoodsInfo() {
      this.totalQuantity = this.productList
        .map((e) => e.purchaseNum)
        .reduce((prev, next) => formatMoneyOfRound(prev) + formatMoneyOfRound(next)) // 总数量
      this.totalMoney = this.productList
        .map((e) => e.sellPrice * e.purchaseNum)
        .reduce((prev, next) => formatMoneyOfRound(prev) + formatMoneyOfRound(next)) // 总金额
      this.totalRebatePrice = this.productList
        .map((e) => e.usedRebateTotal)
        .reduce((prev, next) => formatMoneyOfRound(prev) + formatMoneyOfRound(next)) // 折扣优惠总金额
      const tmpPrice = this.productList
        .map((e) => e.purchaseNum * e.proMatchPrice)
        .reduce((prev, next) => formatMoneyOfRound(prev) + formatMoneyOfRound(next)) // 政策后金额
        this.policyTotalPrice = formatMoneyOfRound(this.totalMoney) - formatMoneyOfRound(tmpPrice) > 0 ? formatMoneyOfRound(this.totalMoney) - formatMoneyOfRound(tmpPrice) : 0// 政策优惠总金额
      this.proMatchPriceAll = this.productList
        .map((e) => e.proMatchPrice * e.purchaseNum)
        .reduce((prev, next) => formatMoneyOfRound(prev) + formatMoneyOfRound(next)) // 总金额
      this.realTotalPrice =
        formatMoneyOfRound(this.proMatchPriceAll) -
        formatMoneyOfRound(this.totalRebatePrice) // 实付金额
      // 计算当前商品可用总折扣金额
      this.orderUsableRebateTotal = this.productList
        .map((e) => e.canUsedRebatePrice)
        .reduce((prev, next) => formatMoneyOfRound(prev) + formatMoneyOfRound(next))
        // this.getRabateRow(1)
    },

    // 删除选中的商品
    onDelete(id) {
      this.productList.splice(
        this.productList.findIndex((e) => {
          return e.goodsId === id
        }),
        1
      )
      this.getRabateList()
    },

    // 禁用弹框
    handleCancel() {
      this.visible = false
    },

    // 表单提交
    toSubmit(status) {
      if (!this.rowData.consigneeType) {
        //return this.$message.warning('请选择购货单位')
        this.$notification.warning({ message: '请选择购货单位' })
        return
      }

      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        this.rowData.orderInfoTax = {}
        this.rowData.orderInfoTax.consigneeType = this.rowData.consigneeType
        this.rowData.usedRebateIds = this.checkedRebateList
        this.rowData.payAmount = this.realTotalPrice.toFixed(2)
        this.rowData.orderInfoDetailDTOList = this.productList

        this.rowData.orderStatus = status

        // if(this.checkRadio == 2) {
        //   this.companyName = ''
        // }

        this.rowData.filesList = this.imageUrl.split(',').map((item) => {
          return {
            fileUrl: item,
          }
        })

        if (this.rowData.customerName == null) {
          //return this.$message.warning('请选择收货地址')
          this.$notification.warning({ message: '请选择收货地址' })
          return
        }

        if (this.rowData.orderInfoDetailDTOList.length <= 0) {
          //return this.$message.warning('请至少添加一个商品')
          this.$notification.warning({ message: '请至少添加一个商品' })
          return
        }
        if(this.handle == 'edit'){
             this.rowData.id = ''
          }
        this.loading =true
            this.axios.post('/api/order/order/orderInfo/dealerOrders',this.rowData).then(res=>{
                this.$notification.success({ message: res.message })
            this.$emit('getConfirmDiscount')
            this.visible = false
            this.loading =false
            this.rowData = {}
            }).catch(()=>{
              this.loading =false
            })
      })
    },
    isShow() {
      const arr = new Map()
      const dealerId = this.rowData.dealerId
      //const orderCode = this.rowData.orderCode
      this.productList = this.productList // 商品信息
      this.checkboxIds = this.checkedRebateList // 默认选中

      let ids = this.productList.map((e) => e.categoryId)
      ids = ids.filter((e) => !arr.has(e) && arr.set(e, 1))
      const obj = {
        dealerId,
        //orderCode,
        cateIds: ids,
      }
      this.axios
        .post(`/api/dealer/dealer/dealerRebate/getDealerRebateListVO`, obj, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          // 获取折扣列表
          this.rebateRowData = res.body
          this.mergeArray()
          this.initOldSelect()
        })
    },
    onCheckRebate(rebate,e){
      e.stopPropagation();
      this.$refs.DealerRebateCheckModal.isShow(rebate)
    },
    initOldSelect() {
      const reqData = {
        dealerId: this.rowData.dealerId,
        orderInfoDetailDTOList: this.productList,
        usedRebateIds: this.checkedRebateList,
        //orderCode: this.rowData.orderCode,
      }

      this.axios.post(`/api/order/order/orderInfo/getNewRebatePriceByUsedRebate`, reqData).then((res) => {
        if (res.code == 200) {
          //this.checkboxIds = res.body.rebateVOList.map(e => e.id)
          this.orderInfoDetailGoodsVOS = res.body.orderInfoDetailGoodsVOS
          this.discountPrice = res.body.rebateTotalListVOList
          this.mergeArray()
        }
      })
    },
    onChangeRebate(val) {
      // 选中折扣不按照点击顺序，默认后端返回顺序
      const tmpList = this.rebateRowData.canUsedList.filter((e) => {
        return val.indexOf(e.id) != -1
      })
      this.checkedRebateList = tmpList.map((e) => e.id)

      const reqData = {
        dealerId: this.rowData.dealerId,
        orderInfoDetailDTOList: this.productList,
        usedRebateIds: this.checkedRebateList,
        //orderCode: this.rowData.orderCode,
      }

      this.axios
        .post(`/api/order/order/orderInfo/getNewRebatePriceByUsedRebate`, reqData)
        .then((res) => {
          if (res.code == 200) {
            if (res.body.rebateVOList != null) {
              this.checkedRebateList = res.body.rebateVOList.map((e) => e.id)
            }
            this.orderInfoDetailGoodsVOS = res.body.orderInfoDetailGoodsVOS
            this.discountPrice = res.body.rebateTotalListVOList
            this.getProductData(res.body.orderInfoDetailGoodsVOS)
            this.mergeArray()
            this.isShow()
          }
        })
        .catch((error) => {
          // 如果接口返回500那么删除最后一个选中
          const end = val.pop()
          this.checkedRebateList = val
        })
    },
    // 追加折扣价格
    mergeArray() {
      // 深拷贝对象
      let canUsedListArr = JSON.parse(JSON.stringify(this.rebateRowData.canUsedList));
      let discountPriceArr =  JSON.parse(JSON.stringify(this.discountPrice));
        canUsedListArr.forEach((item1,index1)=>{
          item1.pr = null
          discountPriceArr.forEach((item2)=>{
            if(item1.id == item2.id) {
              canUsedListArr[index1].pr = item2.rebateAmount
            }
          })
        })

        this.rebateRowData.canUsedList = canUsedListArr

    },
  },
  created() {},
}
</script>

<style lang="scss" scoped>

</style>
